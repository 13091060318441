:root {
  --white: #fff;
  --black: #000;
  --container-width: 1280px;
  --container-padding-vertical: 32px;
  --container-padding-vertical-mobile: 10px;
  --container-padding-horizontal: 48px;
  --container-padding-mobile: 25px;
  --container-padding-tablet: 80px;
  --header-height-mobile: 67px;
}

// Breakpoints
$xxl: '(min-width: 1440px)';
$xxl-max: '(max-width: 1439px)';
$xl: '(min-width: 1200px)';
$xl-max: '(max-width: 1199px)';
$lg: '(min-width: 992px)';
$lg-max: '(max-width: 991px)';
$md: '(min-width: 768px)';
$md-max: '(max-width: 767px)';
$sm: '(min-width: 576px)';
$sm-max: '(max-width: 575px)';
$xs: '(min-width: 400px)';
$xs-max: '(max-width: 399px)';
$desktop-menu: '(min-width: 1001px)';
$mobile-menu: '(max-width: 1000px)';
$ie: (-ms-high-contrast: none), (-ms-high-contrast: active);

// The media mixin
@mixin media($arg) {
  @media #{$arg} {
    @content;
  }
}
