@import '../../css/variables.scss';

.footer-card-list {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin: 3rem 0 0;

  @include media($md-max) {
    padding: 0 35px 45px;
    margin: 0;
  }
}

.footer-card {
  border-radius: 12px;
  background: transparent;
  margin-right: 35px;
  padding: 0px 35px 45px;

  @include media($md-max) {
    padding: 12px 0px;
  }

  a {
    color: #a9a9a9;
  }
}
