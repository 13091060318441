/* li {
  background-color: rgba(214, 214, 214, 0.5);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  list-style: none;

  &:last-child {
    margin-bottom: 0;
  }
} */

.avatar {
  width: 40px;
  height: 40px;
  background-color: #666;
  border-radius: 20px;
}
