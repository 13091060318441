@import '../../css/variables.scss';

.tool-cards-list {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin: 3rem 0 0;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media($md-max) {
    grid-template-columns: repeat(1, 1fr);
  }
}
