@import '../../css/variables.scss';

.tool-card {
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgb(0 0 0 / 55%);
  // border: 1px solid #a9a9a9;
  // rgb(80 78 78 / 34%);
  background: #000;
  padding: 45px 35px;
}
