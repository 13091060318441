.grid__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 7px;
  grid-row-gap: 0;
  justify-items: center;
}

.grid__child-1 {
  grid-area: 1 / 1 / 2 / 2;
}

.grid__child-2 {
  grid-area: 1 / 2 / 2 / 3;
}

.grid__child-3 {
  grid-area: 1 / 3 / 2 / 4;
}
