@import '../../css/variables.scss';

.section {
  h1 {
    font-size: 55px;
    margin-top: 0;
    padding-top: 55px;
    border-top: 1px solid white;

    @include media($md-max) {
      font-size: 45px;
    }
  }

  &:first-child {
    h1 {
      border-top: none;
    }
  }

  h2 {
    color: #f5f5f5;
  }
}
