.normal-card {
  display: flex;
  flex-direction: center;
  align-items: center;
  width: 200px;
  height: 200px;
}

.normal-card--faded {
  opacity: 0.5;
}

.normal-card h1 {
  font-size: 18px;
  color: #fff;
  padding: 5px;
  text-align: center;
  margin: 0 auto;
}

/* expanded card */
.expanded-card {
  position: absolute;
  display: grid;
  width: 500px;
  height: 500px;
  margin-left: -250px;
  margin-top: -250px;
  z-index: 1;
  left: 50%;
  top: 50%;
}

.expanded-card-h {
  font-size: 27px;
  color: #fff;
  width: 300px;
  padding: 5px;
  place-self: end center;
}

.expanded-card p {
  width: 300px;
  padding: 5px;
  place-self: center;
}
