@import '../../css/variables.scss';

#video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;

  .project-card-image-wrapper & {
    border-radius: 12px 12px 0 0;
  }
}
