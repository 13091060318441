@import '../../css/variables.scss';

.header {
  /* border-bottom: 1px solid var(--divider); */
  position: sticky;
  padding: 2.5vw 7px;
  top: 0;
  z-index: 1;
  transition: background-color 0.3s;

  &.active {
    transition: 0.5;
    background-color: rgba(10, 10, 11, 0.6);
  }

  @include media($xxl-max) {
    padding: 2.5vw 4vw;
  }

  @include media($md-max) {
    padding: 2.5vw 6vw;
    max-height: calc(100% - var(--header-height-mobile));
  }
}

.logo {
  font-style: italic;
}

.date {
  color: var(--secondary);
  font-size: 14px;
  text-transform: uppercase;
}

.navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-title {
  font-weight: bold;
  color: white;
  font-size: 1.4em;
  text-decoration: none;

  @media (max-width: 767px) {
    margin: 6px 0 12px;
  }
}

.navbar-sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.navbar-list {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.nav-links {
  text-decoration: none;
  color: white;
  line-height: 2px;
  -webkit-transition: border-bottom 200ms;
  transition: border-bottom 200ms;
  border-bottom: 1px solid transparent;
}

.nav-links:hover {
  /* transition: ease-in 2s; */
  border-bottom: 1px solid #efefef;
}

.navlink-contact {
  margin-right: 1.4vw;
  margin-left: 1.4vw;
}
/*# sourceMappingURL=styles.css.map */
