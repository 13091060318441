@import '../../css/variables.scss';

#chat-id {
  border-radius: 5px;
  // &:hover {
  //   border: 1px solid #3847f3;
  // }
}

.chat-widget__wrapper {
  position: relative;
  height: 600px;
  width: 400px;
  position: fixed;
  top: auto;
  right: 15px;
  bottom: 15px;
  left: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.55);

  @include media($md-max) {
    width: 100%;
    height: calc(100% - var(--header-height-mobile));
    top: 67px;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 15px;
  }
}

.chat-widget {
  border-radius: 12px;
  height: 100%;

  &.minimized {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85px;
    max-width: 200px;
    position: fixed;
    top: auto;
    right: 15px;
    bottom: 15px;
    left: auto;
    background: white;
    border: 1px solid #000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.55);
  }
}

.chat-widget__text {
  text-align: center;
  padding: 30px;
}

.chat-widget__close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  left: auto;
  font-size: 35px;
  color: gray;
  cursor: pointer;
  z-index: 200;

  @include media($md-max) {
    top: 3%;
    right: 5%;
  }
}
