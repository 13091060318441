@import '../../css/variables.scss';

.project-card-list {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin: 3rem 0 0;

  @include media($xl-max) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media($lg-max) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.project-card {
  border-radius: 12px;
  background: #0a0a0b;
  padding: 0 0 45px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 55%);

  .project-card-image-wrapper {
    position: relative;
    padding-top: 75%;
  }

  .project-card-labels {
    width: 25%;
    position: absolute;
    top: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
  }

  .project-card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px 12px 0 0;

    &::after {
      content: '';
      background-color: var(--tan);
      opacity: 0;
    }
  }
  .project-card-subwrapper {
    padding: 0 35px;

    .project-card-title {
      font-weight: 400;
      margin-top: 25px;
    }

    .project-card-description {
      line-height: 1.5rem;
    }
  }
}
